import React from "react"
import { ExhibitionContainer } from "./styles"
import Layout from "../share/layout";

const Nivelacion = () => {
  return (
    <Layout>
      <ExhibitionContainer>
        <div className="title-exh">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[Expresión Gráfica & Nivelaciones]</h2>
          <a href='/galeria/arquitectura-upc-2024-2'><h4>VOLVER</h4></a>
        </div>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&uid=58146&exhibition=12062729'
            title='Arquitecturas de la Imaginación [Expresión Gráfica & Nivelaciones]'
          />
        </div>
      </ExhibitionContainer>
    </Layout>
  )
}

export default Nivelacion
